import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useInView } from 'react-intersection-observer';

const IframeWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  background-color: ${props => props.theme.accentColor};
  border-radius: 10px;

  ${props =>
    props.livedemo &&
    css`
      @media ${props => props.theme.media.tablet} {
        min-height: 100px;
      }
    `}

 @media ${props => props.theme.media.tablet} {
    min-height: 100px;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const LoaderWrapper = styled.div`
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10%;
  }

  .paths {
    > * {
      animation-name: pathAni;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      animation-fill-mode: alternate;
      transform-origin: 50% 50%;
      transform: scale(0.8);
    }
    path:nth-child(1) {
      animation-delay: 0.2s;
    }
    path:nth-child(2) {
      animation-delay: 0.3s;
    }
    path:nth-child(3) {
      animation-delay: 0.4s;
    }
  }

  @keyframes pathAni {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;
const Loader = () => {
  let maskid = `mask-${Math.random()}`;
  let paintid = `paint-${Math.random()}`;
  return (
    <LoaderWrapper>
      <svg
        width="247"
        height="210"
        viewBox="0 0 247 210"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id={maskid}
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="265"
          height="210"
        >
          <rect width="265" height="210" fill={`url(#${paintid})`} />
        </mask>
        <g className="paths" mask={`url(#${maskid})`}>
          <path
            opacity="0.7"
            d="m63.26492,5.54614l49.72537,0l0,14.1711c0.74651,21.44644 10.53975,41.58964 26.95039,55.41912l34.41553,32.51761c21.95255,22.1803 21.95255,57.91171 0,80.09201c-10.5271,10.89404 -25.09043,16.92939 -40.2358,16.70165l-77.94104,0l0,-49.72537l77.68799,0c1.88527,0 3.6946,-0.7212 5.0611,-2.02444c2.54321,-2.79625 2.54321,-7.07289 0,-9.86916l-26.19123,-25.30553c-33.15025,-30.36664 -49.72537,-63.04874 -49.72537,-98.05894l0.25305,-13.91804l0,-0.00001l0.00001,0z"
            fill={`url(#${paintid})`}
          />
          <path
            opacity="0.7"
            d="m151,30.09091c0,-13.3099 10.31228,-24.09091 23.04348,-24.09091c12.7312,0 23.04348,10.78101 23.04348,24.09091c0,13.3099 -10.31228,24.09091 -23.04348,24.09091c-12.7312,0 -23.04348,-10.78101 -23.04348,-24.09091z"
            fill={`url(#${paintid})`}
          />
          <path opacity="0.7" d="" fill={`url(#${paintid})`}></path>
          <path opacity="0.7" d="" fill={`url(#${paintid})`}></path>
        </g>
        <defs>
          <linearGradient
            id={paintid}
            x1="0"
            y1="0"
            x2="203.966"
            y2="257.386"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF4949" />
            <stop offset="1" stopColor="#FF2222" />
          </linearGradient>
        </defs>
      </svg>
    </LoaderWrapper>
  );
};

const IFrame = ({ src, livedemo }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const handleLoaded = () => {
    setIsLoading(false);
  };

  return (
    <IframeWrapper livedemo={livedemo} className="iframe-wrapper" ref={ref}>
      <iframe
        title={src}
        style={{ opacity: isLoading ? '0' : '1' }}
        src={inView ? src : undefined}
        onLoad={handleLoaded}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope"
        allowFullScreen
      />
      {isLoading && <Loader />}
    </IframeWrapper>
  );
};

IFrame.defaultProps = {
  livedemo: false,
};
IFrame.propTypes = {
  src: PropTypes.string.isRequired,
  livedemo: PropTypes.bool,
};

export default IFrame;
